<template>
  <v-autocomplete
    class="autocomplete"
    outlined
    flat
    :items="phoneCodes"
    solo
    dense
    hide-details
    auto-select-first
    return-object
    append-icon=""
    v-model="selectedCode"
    :height="height ? height : 'auto'"
    label=""
    aria-labelledby="phoneCodeField"
    aria-selected="false"
    tabindex="0"
    :menu-props="menuStyles"
  >
    <template v-slot:selection="{ item }">
      <div class="d-flex align-center pb-1">
        <vue-country-flag :country="item.value.countryCode" size="normal" />
        <span class="ml-3 mt-2">+{{ item.value.phoneCode }}</span>
        <v-icon right color="grey" small class="phone-chevron">
          mdi-chevron-down
        </v-icon>
      </div>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>
          <vue-country-flag :country="item.value.countryCode" size="normal" />
          <span class="mx-3 pt-1 country-title">{{ item.text }}</span>
          <span class="pt-1 country-title grey--text">
            +{{ item.value.phoneCode }}
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import countryCodesWithEmoji from '@/assets/json/countryCodesWithEmoji.json';

export default {
  name: 'CountryCodeAutoComplete',
  props: {
    value: String,
    height: Number,
  },

  data() {
    const phoneCodes = countryCodesWithEmoji.countryCodes;

    const firstSelection = phoneCodes.find((phoneCode) => {
      if (this.value) {
        return phoneCode.value.phoneCode === this.value;
      } else {
        return phoneCode.value.countryCode === 'US';
      }
    });
    return {
      phoneCodes,
      selectedCode: firstSelection,
    };
  },

  watch: {
    selectedCode: {
      immediate: true,
      handler(selectedCode) {
        if (selectedCode && selectedCode.value)
          this.$emit('change', selectedCode.value);
      },
    },
  },

  computed: {
    menuStyles() {
      return this.$vuetify.breakpoint.smAndDown
        ? {
            maxWidth: `calc(100vw - 40px)`,
          }
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-chevron {
  position: absolute;
  right: -7px;
  top: 15px;
}

::v-deep.autocomplete {
  span {
    position: relative !important;
  }

  .v-input__slot {
    min-height: 45px !important;
    margin: 0;
    color: #141414;

    input {
      caret-color: transparent;
    }
  }

  .flag {
    border-radius: 5px;
  }
}

::v-deep {
  .v-list-item {
    padding: 0 10px;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 100vw;
    }

    &__content {
      padding: 5px 0;
    }

    &__title {
      display: flex;
      align-items: center;

      & .flag {
        border-radius: 5px;
      }
    }
  }
}

.country {
  &-title {
    @include font-size(16, 150, 500);
  }

  &-code {
    @include font-size(16, 150, 400);
  }
}
</style>
